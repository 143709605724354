<template>
  <v-card>
    <v-card-title> Deactivated Accounts </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i" :class="`text-${header.align || 'left'}`">
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.company }}</td>
            <td>{{ item.postage_spent }}</td>
            <td>{{ item.created_at | readableDate(getTz()) }}</td>
            <td>{{ item.deleted_at | readableDate(getTz()) }}</td>
            <td class="text-center">
              <v-btn color="warning" @click="reactivate(item.id)">Reactivate</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import DataTable from '@/components/data-table/DataTable.vue'

import axios from 'axios'
import _ from 'lodash'

export default {
  components: {
    DataTable,
  },
  data: () => ({
    selected: [],
    options: {},
    loading: false,
    totalItems: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    headers: [
      { text: 'Name', value: 'name', },
      { text: 'Email', value: 'email', },
      { text: 'Company', value: 'company', },
      { text: 'Postage Spent', value: 'postage_spent', },
      { text: 'Created At', value: 'created_at', },
      { text: 'Deleted At', value: 'deleted_at', },
      { text: 'Actions', value: 'actions', align: 'center' },
    ],
    items: [],
  }),

  computed: {
    ...mapGetters(['getTz']),
  },

  mounted() {
    document.title = 'Deactivated';

    this.fetchUser()
  },

  methods: {
    async fetchUser() {
      try {
        this.loading = true
        if (this.$root.progressbar) {
          this.$root.progressbar.show()
        }
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data } = await axios.get(`${url}/api/user/deactivated`, {
          headers: { Authorization: `Bearer ${token}` },
        })

        this.items = data.map(u => {
          return {
            id: u.id,
            name: u.name,
            email: u.email,
            company: u.company,
            postage_spent: u.postage_spent,
            created_at: u.created_at,
            deleted_at: u.deleted_at,
          }
        })
      } catch (error) {
        console.error('error', error)
      } finally {
        this.loading = false
        if (this.$root.progressbar) {
          this.$root.progressbar.hide()
        }
      }
    },

    async reactivate(id) {
      try {
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data } = await axios.patch(`${url}/api/user/${id}/activate`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )

        this.$root.snackbar.show({ message: 'User successfully reactivated.', color: 'success' });
        await this.fetchUser()
      } catch (error) {
        console.error('error', error)
      }
    }
  },
}
</script>
